import React from "react"
import clsx from "clsx"
import { useStaticQuery, graphql, navigate } from "gatsby"
// import MDReactComponent from 'markdown-react-js';

// @MaterialUI
import { makeStyles } from "@material-ui/core/styles"
import Carousel from "react-material-ui-carousel"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import IconButton from "@material-ui/core/IconButton"
import Typography from "@material-ui/core/Typography"
import AddIcon from "@material-ui/icons/Add"

// @Local
import { getDateFormat } from "../../utils/date"

export default function Block() {
  const newsItems = useStaticQuery(graphql`
    query {
      allStrapiArticle(
        filter: {
          article_type: { name: { eq: "News" } }
          promoted: { eq: true }
          published: { eq: true }
        }
        limit: 4
        sort: { fields: date, order: DESC }
      ) {
        edges {
          node {
            strapiId
            title
            date
            content
            extlink
            image {
              publicURL
            }
            fields {
              slug
            }
          }
        }
      }
    }
  `)
  return (
    <Carousel
      className="SecondExample"
      autoPlay={true}
      timer={500}
      animation={"fade"}
      indicators={false}
      timeout={500}
      navButtonsAlwaysVisible={true}
    >
      {newsItems.allStrapiArticle.edges.map((item, index) => {
        return <Item item={item.node} key={index} />
      })}
    </Carousel>
  )
}

function Item(props) {
  const classes = useStyles()
  const { item } = props
  const { extlink, image = null } = item

  return (
    <Card
      className={clsx(classes.root, "img-bg-cover-center-news")}
      style={image && image.publicURL ? { backgroundImage: `url(${image.publicURL})` } : null}
      onClick={() => {
        !extlink
          ? navigate(`/contenido/actualidad/${item.fields.slug}`)
          : window.open(extlink, "_blank")
      }}
    >
      <div className={classes.details}>
        <CardContent className={classes.content}>
          <Typography className={classes.newsDate}>
            {getDateFormat(item.date)}
          </Typography>
          <Typography component="h2" className={classes.newsHeading}>
            {item.title}
          </Typography>
        </CardContent>
        {/* <div className={classes.controls}>
          <IconButton aria-label="play/pause">
            <AddIcon className={classes.playIcon} />
          </IconButton>
        </div> */}
      </div>
    </Card>
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    height: "200px",
    maxHeight: "200px",
    cursor: "pointer",
    width: "100%",
  },
  details: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
  },
  content: {
    flex: "1 0 auto",
    position: "relative",
  },
  controls: {
    position: "absolute",
    bottom: 38,
    right: 38,
    zIndex: 2,
  },
  playIcon: {
    height: 38,
    width: 38,
    color: "#FFF",
    borderRadius: "50%",
    backgroundColor: "#365eab",
  },
  newsDate: {
    fontSize: 10,
    fontWeight: 500,
    color: "#FFF",
    textShadow: "1px 0px 1px #000000",
    marginBottom: 20,
  },
  newsHeading: {
    color: "#FFF",
    fontSize: 20,
    fontWeight: "bold",
    textShadow: "1px 1px 5px #000000",
  },
}))
