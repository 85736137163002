import React, { useState, useEffect } from "react"
import clsx from "clsx"
import { StaticQuery, navigate, graphql } from "gatsby"

// @Material UI
import Grid from "@material-ui/core/Grid"
import IconButton from "@material-ui/core/IconButton"

// Local Components.
import ImagesSrc from "../../images/ImagesSrc"
import screenConvert from "../_misc/ScreenConvert"

function BtnCard({ data }) {
  const [pathName, setPathName] = useState(null)
  const [pathTo, setPathTo] = useState(null)
  let { text: title, link, group, image, idPage = null } = data

  useEffect(() => {
    if (!pathName && window.location.pathname) {
      setPathName(window.location.pathname)
    }
  }, [pathName])

  useEffect(() => {
    if (idPage) {
      const pathPieces = idPage.split(":")
      if (pathPieces[0]) {
        let pathTo = screenConvert[pathPieces[0]]
        if (pathPieces[1]) {
          pathTo += `/${pathPieces[1]}`
        }
        setPathTo(pathTo)
      }
    }
  }, [idPage])

  const onClick = () => {
    if (link && link !== null && link !== "_none" && link !== "NO-LINK") {
      window.open(link, "_blank")
    } else if (pathTo && pathName !== pathTo) {
      navigate(pathTo)
    }
  }

  return (
    <Grid item xs={6} md={6} lg={6}>
      <IconButton
        aria-label="delete"
        onClick={onClick}
        className={`topButtonItem`}
      >
        <div className="iconWrapper">
          <img
            src={image.publicURL}
            width={"90px"}
            height={"90px"}
            alt={title}
          />
          <p className="btnTopIconText">{title}</p>
        </div>
      </IconButton>
    </Grid>
  )
}

function TopButtons() {
  return (
    <Grid
      container
      spacing={1}
      justify="center"
      alignItems="center"
      className="wrapperTopButtons"
    >
      <StaticQuery
        query={queryTopButtons}
        render={data =>
          data.strapiTopDesktopButtons.buttons.map(item => (
            <BtnCard data={item} />
          ))
        }
      />
    </Grid>
  )
}

export default TopButtons

// Queries Single components @STRAPI
const queryTopButtons = graphql`
  query {
    strapiTopDesktopButtons {
      blockTitle
      showTitle
      buttons {
        link
        text
        idPage
        image {
          publicURL
        }
      }
    }
  }
`
