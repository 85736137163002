export default {
  "@screenAllianceDetail": "/app/beneficios",
  "@screenAllianceList": "/app/beneficios",
  "@screenBasic": "/contenido/basica",
  "@screenBirthdayList": "/app/cumpleanos",
  "@screenCalendar": "/app/eventos",
  "@screenCalendarEventDetail": "/app/eventos",
  "@screenDirectoryList": "/app/directorio",
  "@screenDynamic": "/contenido/pagina",
  "@screenGalleryDetail": "/app/multimedia",
  "@screenGalleryList": "/app/multimedia",
  "@screenHome": "/app/home",
  "@screenInit": null,
  "@screenJobDetail": "/contenido/vacantes",
  "@screenJobList": "/app/vacantes",
  "@screenLogOut": null,
  "@screenLogin": "login",
  "@screenLoginMail": null,
  "@screenLoginMailRegister": null,
  "@screenManageForms": "/app/solicitudes",
  "@screenMenu": null,
  "@screenNewsDetail": "/contenido/actualidad",
  "@screenNewsList": "/app/actualidad",
  "@screenProductDetail": "/app/productos",
  "@screenProductList": "/app/productos",
  "@screenUserHighLightDetail": "/contenido/reconocimientos",
  "@screenUserHighLightList": "/contenido/reconocimientos",
  "@screenUserProfile": "/app/perfil",
  "@screenVideoPlayer": null,
}
