export default {
  // anonymous: require("@images/anonymous.png"),
  // notFound: require('@images/notFound.jpg'),
  // loginGoogle: require('@images/g-logo.png'),
  // logoMedium: require('@images/logoMedium.png'),
  // userProfile: require('@images/userprofile.png'),
  blue_beach: require("./icons/blue_beach.png"),
  blue_calendar: require("./icons/blue_calendar.png"),
  blue_mailsimbol: require("./icons/blue_mailsimbol.png"),
  blue_message: require("./icons/blue_message.png"),
  blue_money: require("./icons/blue_money.png"),
  blue_person: require("./icons/blue_person.png"),
  blue_phone: require("./icons/blue_phone.png"),
  blue_search: require("./icons/blue_search.png"),
  blue_talking: require("./icons/blue_talking.png"),
  blue_trophy: require("./icons/blue_trophy.png"),
  gray_ambulance: require("./icons/gray_ambulance.png"),
  gray_beach: require("./icons/gray_beach.png"),
  gray_book: require("./icons/gray_book.png"),
  gray_calendar: require("./icons/gray_calendar.png"),
  gray_cardprofile: require("./icons/gray_cardprofile.png"),
  gray_castle: require("./icons/gray_castle.png"),
  gray_chart: require("./icons/gray_chart.png"),
  gray_company: require("./icons/gray_company.png"),
  gray_document: require("./icons/gray_document.png"),
  gray_documentA: require("./icons/gray_documentA.png"),
  gray_gift: require("./icons/gray_gift.png"),
  gray_like: require("./icons/gray_like.png"),
  gray_medal: require("./icons/gray_medal.png"),
  gray_person: require("./icons/gray_person.png"),
  gray_pluma: require("./icons/gray_pluma.png"),
  gray_sun: require("./icons/gray_sun.png"),
  gray_talkingA: require("./icons/gray_talkingA.png"),
}
